/*
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
*/
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.font-default {
  font-family: "Roboto", sans-serif;
} /* Default font */
.font-Alegreya {
  font-family: "Alegreya", serif;
}
.font-BebasNeue {
  font-family: "Bebas Neue", cursive;
}
.font-CormorantGaramond {
  font-family: "Cormorant Garamond", serif;
}
.font-CrimsonText {
  font-family: "Crimson Text", serif;
}
.font-EBGaramond {
  font-family: "EB Garamond", serif;
}
.font-JosefinSans {
  font-family: "Josefin Sans", sans-serif;
}
.font-Lora {
  font-family: "Lora", serif;
}
.font-Merriweather {
  font-family: "Merriweather", serif;
}
.font-Montserrat {
  font-family: "Montserrat", sans-serif;
}
.font-NunitoSans {
  font-family: "Nunito Sans", sans-serif;
}
.font-OpenSans {
  font-family: "Open Sans", sans-serif;
}
.font-Optima {
  font-family: "Optima", sans-serif;
}
.font-Oswald {
  font-family: "Oswald", sans-serif;
}
.font-PlayfairDisplay {
  font-family: "Playfair Display", serif;
}
.font-Poppins {
  font-family: "Poppins", sans-serif;
}
.font-PTSerif {
  font-family: "PT Serif", serif;
}
.font-Quicksand {
  font-family: "Quicksand", sans-serif;
}
.font-Raleway {
  font-family: "Raleway", sans-serif;
}
.font-Roboto {
  font-family: "Roboto", sans-serif;
}
.font-SourceSansPro {
  font-family: "Source Sans Pro", sans-serif;
}
.font-Urbanist {
  font-family: "Urbanist", sans-serif;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.flash-notification {
  animation: flash 1s ease-in-out infinite;
}

html {
  scroll-behavior: smooth;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: initial;
  vertical-align: initial;
}

body {
  font-family: "Outfit", sans-serif;
  font-weight: 400;
}
#signin-main {
  font-family: "Outfit", sans-serif;
}

.tabs-container {
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth; /* Ensures smooth scrolling */
}

.splide__arrow--prev {
  color: blue !important;
}
.splide__arrow--next {
  color: red !important; /* Change this to your desired color */
}

.splide__arrow {
  cursor: pointer;
  font-size: 13px; /* Change the font size of the arrows */
  color: rgb(0, 4, 255); /* Change the color of the arrows */
  /* You can add more custom styles here, such as background-color, padding, etc. */
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #736473;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0a0a0a;
}

#input-textarea {
  overflow: hidden;
}

.customPhoneInput {
  width: 200px;
  height: 30px;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  transform: scale(0);
  /* box-shadow: inset 1em 1em ; */
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.3em;
  height: 1.3em;
  border-radius: 0.15em;
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.7em;
  height: 0.7em;
  transform: scale(0);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

abbr[title] {
  text-decoration: none !important;
  border: none !important;
  cursor: default !important;
}

.progressbar {
  counter-reset: step;
}

.progressbar li {
  list-style: none;
  float: left;
  justify-content: space-between;
  width: 33.33%;
  position: relative;
  text-align: center;
}

.progressbar li::before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  display: flex;
  background-color: rgb(239 68 68);
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  color: white;
}

.progressbar li::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #a6a4a4;
  top: 13px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child::after {
  content: none;
}

.progressbar li:last-child::before {
  background-color: #a6a4a4;
}

.progressbar li.active {
  color: white;
}

.progressbar li.active::before {
  border-color: rgb(239 68 68);
}

.progressbar li.active + li::after {
  background-color: rgb(239 68 68);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}

.menu_border {
  position: relative;
  display: inline-block;
}
.menu_border:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: auto;
  width: 50%;
  height: 0.15rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

input:checked + div {
  @apply border-primaryOrange;
}
input:checked + div svg {
  @apply block;
}

.react-draggable-transparent-selection {
  z-index: -1;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.toggle {
  opacity: 0;
  position: absolute;
}

.label {
  border-radius: 99px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  position: relative;
  height: 1.1rem;
  width: 2rem;
  transform: scale(1.5);
}

.label .ball {
  border-radius: 50%;
  position: absolute;
  transform: translateX(0px);
  transition: transform 0.2s linear;
}

.toggle:checked + .label .ball {
  transform: translateX(15px);
}

label {
  user-select: none;
}
.bottomMenu {
  max-height: 50vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/* ---------------------------------------------- */

.dark-theme {
  --color-primary: 42, 45, 52;
  --color-bg-primary: 61, 60, 60;
  --color-primaryOrange: 255, 100, 57;
  --color-text: 255, 255, 255;
  --color-text-inverted: 51, 51, 51;
  --color-pop-up: 15, 23, 42;
  --color-light: 255, 255, 255;
  --color-dark: 51, 51, 51;
  --color-grey: 89, 89, 89;
}

.light-theme {
  --color-primary: 252, 251, 244;
  --color-bg-primary: 252, 251, 244;
  --color-primaryOrange: 255, 100, 57;
  --color-text: 61, 60, 60;
  --color-text-inverted: 255, 255, 255;
  --color-pop-up: 203, 213, 225;
  --color-light: 255, 255, 255;
  --color-dark: 51, 51, 51;
  --color-grey: 89, 89, 89;
}

body {
  @apply bg-cream dark:bg-dark text-dark dark:text-light font-roboto;
}

@layer components {
  input[type="radio"] {
    @apply border-[0.1rem] border-primaryOrange before:shadow-customShadow1;
  }

  input[type="checkbox"] {
    @apply border-[0.1rem] border-primaryOrange before:shadow-customShadow1;
  }

  .btn-primary {
    @apply bg-primaryOrange py-[.5rem] px-[1rem] rounded-md text-light font-medium tracking-wider select-none;
  }

  .cancle-btn {
    @apply btn-primary text-center !bg-dark;
  }

  .btn-gap {
    @apply flex items-center justify-start gap-4;
  }

  .form-input {
    @apply outline-none px-[0.6rem] py-[0.6rem] text-[0.8rem] w-full dark:bg-softGray dark:bg-opacity-30 bg-white shadow-sm rounded-md border border-transparent focus:border dark:focus:border-[#15803d];
  }
  .outline-Input {
    @apply px-[0.6rem] py-[0.6rem] text-[0.8rem] w-full  dark:bg-softGray dark:bg-opacity-30 bg-white shadow-sm rounded-md border-none  dark:focus:outline-double outline-primaryOrange;
  }
  .directory-input {
    @apply outline-none px-[0.6rem] py-[0.6rem] text-[0.8rem] w-full dark:bg-softGray dark:bg-opacity-30 bg-white shadow-sm  border border-transparent focus:border dark:focus:border-[#15803d];
  }
  .copy {
    @apply outline-none px-[0.6rem] py-[0.6rem] text-[0.8rem]  dark:bg-softGray dark:bg-opacity-30 bg-white shadow-sm border border-transparent focus:border dark:focus:border-[#15803d];
  }

  .light_dark_icon {
    @apply h-[1.5rem] w-[1.5rem] p-[0.15rem];
  }

  .label {
    @apply bg-cream dark:bg-dark border;
  }

  .label .ball {
    @apply dark:bg-white bg-darkGray 	w-[1.1rem] h-[1.1rem] -left-[0.1rem];
  }

  .btn-cart {
    @apply w-[2rem] h-[2rem] text-xl rounded-md bg-primaryOrange text-light;
  }

  .btn-menu {
    @apply w-[2rem] h-[2rem] to-center text-[1.6rem]  rounded-full text-primaryOrange border-[3px] border-primaryOrange bg-light dark:bg-dark;
  }

  .menu-icon {
    @apply w-[2rem] h-[2rem] cursor-pointer p-1;
  }

  .to-center {
    @apply flex justify-center items-center;
  }

  .btn-primaryOrange {
    @apply inline-block bg-skyBlue rounded-full px-3 py-1 text-sm font-semibold text-white mt-20 ml-20;
  }

  .submit-btn {
    @apply btn-primary;
  }

  .step-item {
    @apply relative flex flex-col justify-center items-center w-full;
  }
  .step-item:not(:first-child):before {
    @apply content-[''] bg-[#e2e8f0] absolute w-full h-[3px] right-2/4 top-1/3 -translate-y-2/4;
  }
  .step {
    @apply w-7 h-7 flex items-center justify-center z-10 relative bg-white text-darkGray rounded-full font-semibold text-[14px];
  }
  .active .step {
    @apply bg-primaryOrange text-white;
  }
  .complete .step {
    @apply bg-primaryOrange text-white;
  }
  .complete p {
    @apply text-white;
  }
  .complete:not(:first-child):before,
  .active:not(:first-child):before {
    @apply bg-primaryOrange;
  }
  .input {
    @apply w-full h-[45px] mt-2 px-4 py-2 text-sm border rounded-lg focus:outline-none  focus:ring-2 focus:ring-blue-400 focus:border-blue-400;
  }
  .btn {
    @apply w-full h-[45px] px-4 py-2 text-white bg-[#2563eb] rounded-lg hover:bg-[#1447E6] focus:outline-none focus:ring-2 focus:ring-[#2b7fff];
  }

  .dot {
    @apply inline-block;
    animation: bounce 1.2s infinite ease-in-out, glow 1.5s infinite alternate;
  }
  .dot:nth-child(1) {
    animation-delay: 0.2s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.4s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  .dot:nth-child(4) {
    animation-delay: 0.8s;
  }

  .glow-text {
    animation: glow 1.5s infinite alternate;
    font-weight: bold; /* Optional */
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
  }

  @keyframes glow {
    from {
      filter: drop-shadow(0 0 10px rgba(255, 217, 0, 0.382));
    }
    to {
      filter: drop-shadow(0 0 20px rgba(255, 217, 0, 0.242));
    }
  }

  .thinking-text {
    animation: fadeInOut 1.5s infinite;
  }

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.textField {
  color: white;
}

.blink {
  -webkit-animation: blink 0.5s linear infinite;
  -moz-animation: blink 0.5s linear infinite;
  -ms-animation: blink 0.5s linear infinite;
  -o-animation: blink 0.5s linear infinite;
  animation: blink 0.5s linear infinite;
}

#hidden {
  display: none;
}
#hidden2 {
  display: none;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dot-animation {
  display: inline-block;
  animation: dot 1.5s ease-in-out infinite;
}

.dot-animation:nth-child(1) {
  animation-delay: 0s;
}

.dot-animation:nth-child(2) {
  animation-delay: 0.3s;
}

.dot-animation:nth-child(3) {
  animation-delay: 0.6s;
}

.blinkbox {
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  -ms-animation: blink 1s linear infinite;
  -o-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
}
@-webkit-keyframes blinkbox {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes blinkbox {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes blinkbox {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes blinkbox {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blinkbox {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*Puslse*/
.animate-pulse {
  -webkit-animation: pulse 3s ease-in-out infinite;
  -moz-animation: pulse 3s ease-in-out infinite;
  -ms-animation: pulse 3s ease-in-out infinite;
  -o-animation: pulse 3s ease-in-out infinite;
  animation: pulse 3s ease-in-out infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-moz-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@-ms-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@-o-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse2 {
  -webkit-animation: pulse 2s ease-in-out infinite;
  -moz-animation: pulse 2s ease-in-out infinite;
  -ms-animation: pulse 2s ease-in-out infinite;
  -o-animation: pulse 2s ease-in-out infinite;
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-moz-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@-ms-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@-o-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.ag-overlay-loading-wrapper {
  background-color: rgba(
    0,
    0,
    0,
    0.215
  ) !important; /* Semi-transparent background */
  backdrop-filter: blur(2px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(2px); /* For Safari support */
  color: white !important; /* Change text color */
}

.ag-overlay-loading-center {
  background-color: rgba(
    124,
    58,
    237,
    0.8
  ) !important; /* Semi-transparent loading box */
  color: white !important; /* Change text color */
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  backdrop-filter: blur(10px); /* Blur inside the loading box */
  -webkit-backdrop-filter: blur(10px);
}

.domclass {
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.domclass2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.domclass3 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
#Outfit {
  font-family: "Outfit", sans-serif;
}

@keyframes mySyncRotate {
  50% {
    transform: rotate(180deg);
  }
}

.mySyncRotate {
  animation: mySyncRotate 1s ease-in-out infinite;
}

@keyframes ping {
  0% {
    transform: scale(0.8);
    opacity: 0.85;
  }
  50% {
    transform: scale(1.6);
    opacity: 0;
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

.custom-ping {
  animation: ping 2.5s cubic-bezier(1, 1.3, 1, 1) infinite;
  border-radius: 10px;
  display: inline-block;
  height: 55px; /* Change the size as needed */
  width: 100%; /* Change the size as needed */
}

.animation-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.loader {
  position: relative;
  border: 24px solid #ff6339;
  border-radius: 50%;

  box-sizing: border-box;
  animation: eat 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  background: #d2ccca;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-sizing: border-box;
  opacity: 0;
  animation: move 2s linear infinite;
}
.loader::before {
  animation-delay: 1s;
}
@keyframes eat {
  0%,
  49% {
    border-right-color: #ff6339;
  }
  50%,
  100% {
    border-right-color: #fcfbf4;
  }
}
@keyframes move {
  0% {
    left: 75px;
    opacity: 1;
  }
  50% {
    left: 0px;
    opacity: 1;
  }
  52%,
  100% {
    left: -5px;
    opacity: 0;
  }
}

.loader1 {
  position: relative;
  width: 120px;
  height: 90px;
  margin: 0 auto;
}
.loader1:before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #ff3d00;
  animation: loading-bounce 0.5s ease-in-out infinite alternate;
}
.loader1:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 7px;
  width: 45px;
  border-radius: 4px;
  box-shadow: 0 5px 0 #fff, -35px 50px 0 #fff, -70px 95px 0 #fff;
  animation: loading-step 1s ease-in-out infinite;
}
@keyframes loading-bounce {
  0% {
    transform: scale(1, 0.7);
  }
  40% {
    transform: scale(0.8, 1.2);
  }
  60% {
    transform: scale(1, 1);
  }
  100% {
    bottom: 140px;
  }
}
@keyframes loading-step {
  0% {
    box-shadow: 0 10px 0 rgba(0, 0, 0, 0), 0 10px 0 #ff3d00,
      -35px 50px 0 #ff3d00, -70px 90px 0 #ff3d00;
  }
  100% {
    box-shadow: 0 10px 0 #fcfbf4, -35px 50px 0 #ff3d00, -70px 90px 0 #ff3d00,
      -70px 90px 0 #fcfbf4;
  }
}
@media print {
  body {
    width: 100%; /* Ensure the body takes full width */
  }
  .page {
    page-break-after: always;
  }
  .element {
    page-break-inside: avoid; /* Prevent cutting elements across pages */
  }
}

html {
  -webkit-print-color-adjust: exact;
}

.custom-row.group:hover {
  background-color: #dfe0e58f !important;
  transition: background-color 0.3s ease-in-out;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
